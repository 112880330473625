
body {
	font-weight: 400;
	font-family: 'Open Sans';
	font-size: 1em;		
}

.navbar-brand {
	padding-top:0;
	padding-bottom: 0;
}

.navbar {
    padding: 0rem 1rem;
}

a.nav-link {
	font-size: 10pt;
}

.navbar-dark .navbar-nav .nav-link {
	color: #fff;
	padding: 0px;
	border-radius: 0px;
	background: none;
	margin: 14px;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
	border-bottom: 2px solid #fff;
	font-weight: 600;
}

.phone-dropdown .btn-default {
	color: #fff !important;
	font-weight: 600;
	font-size: 0.8em;
	background: #dd6247 !important;
	border: none;
}

.phone-dropdown .dropdown-menu {
	color: rgb(255, 255, 255);
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	background: rgb(221, 98, 71) !important;
}

.phone-dropdown .dropdown-menu li a {
	font-weight: 600;
	font-size: 0.8em;
	padding: 3px 18px;
	color: rgb(255, 255, 255);
}

.phone-dropdown .dropdown-menu li a:hover {
	text-decoration: none;
	color: rgb(255, 255, 255);
}

.phone-dropdown .btn:focus {
	outline: 0;
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
}
.profits-header {
	margin-top: 40px;
	margin-bottom: 30px;
	text-transform: uppercase;
	font-size: 1.5em;
	text-align: center;
}
.profits-header span {
	background: #fff;
	padding: 0px 30px;
	color: #4f4f4f;
	display: inline-block;
}

.profits-header:before {
	border-top: 1px solid #43b02a;
	content: "";
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	bottom: 0;
	width: 95%;
	z-index: -1;
}

#item-image{
	height:300px;
	max-width: 300px;
	background-color: #fff;
	border: 2px solid #ccc;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 90%;
}
#photo-roll {
    height: 68px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 5px 0;
    max-width: 300px;
    text-align: center;
}

#photo-roll div {
	display: inline-block;
	margin: 0 5px 0 0;
	width: 40px;
	height: 40px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border: 2px solid #ccc;
	background-color: #fff;
}

#photo-roll div:hover , .selected-item-photo{
	border: 2px solid #43b02b !important;
	cursor: pointer;
}

label {
	display: inline-block;
	margin-bottom: 0.1rem;
	text-transform: uppercase;
	font-size: 9pt;
	color: #787878;
}


.btn-oval {
	border-radius: 30px;
}

h3{
	font-weight: bold;
	color: #323232;
	line-height: 140%;
	letter-spacing: 0.1pt;
}

.description{
	padding-top: 10px;
	padding-bottom: 20px;
	font-size: 14pt;
	color: #3d3d3d;
}

.fn-banner {
	max-width: 445px;
	height: 445px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

.benefit {
	margin: 10px 0 30px;
	padding-left: 50px;
	background-repeat: no-repeat;
	background-size: 30px 30px;
	background-position: left 6px;
}
.benefit b {
	font-size: 1.1em;
	font-weight: 500 !important;
	text-transform: uppercase;
}

.benefit p{
	font-size: 1.1em;
	font-weight: 200 !important;
}

.docimg {
	height: 300px;
	max-width: 300px;
	overflow: hidden;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
}




/* Корзина и все что ее касается */


div#cart_widget {
	display: block;
	position: fixed;
	left: 50px;
	bottom: 50px;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #43b02b;
	color: #fff;
	text-align: center;
	font-size: 23pt;
	line-height: 194%;
	cursor: pointer;
}

#cart_widget:hover{
	background-color: #287717;
}

#cart_widget_container {
    display: block;
    position: fixed;
    bottom: 112px;
    left: 50px;
    height: 200px;
    width: 313px;
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.12);
    overflow-y: auto;
}

#cart_empty {
    padding: 40px 0;
    text-transform: uppercase;
    color: #ccc;
}

#cart_summary {
	text-align: center;
    font-size: 14pt;
    color: #8d8d8d;
    margin-bottom: 10px;
}

.cart_line {
	padding: 4px;
	border-bottom: 2px solid #f2f2f2;
	font-size: 9pt;
}

.cart-image-mini {
    display: block;
    width: 50px;
    height: 32px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}